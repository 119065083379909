import axios from "axios";
import store from "@/store";
import router from '@/router'
import {guest_route} from "@/config/auth";

axios.interceptors.response.use(
    response => response,
    async error => {
        if (401 === error.response.status) {
            // clear booking and history
            await store.dispatch('user/clearStoredBookings');
            await store.dispatch('user/clearStoredBalanceHistory');

            // clear new quote
            await store.dispatch('checkout/clearQuote');
            
            if (router.history.current.name !== guest_route) {
                // Log out the user.
                await store.dispatch('auth/logout');

                router.push({name: guest_route});
            }
        }

        return Promise.reject(error);
    }
);
