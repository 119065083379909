import * as types from '../mutationTypes'
import VueCookies from 'vue-cookies'
import axios from "axios";
import {POST_NEW_QUOTE, STORE_BOOKING} from "@/config/api";
import {RATING_CONTAINER_OFFER} from "../../config/api";

const referenceCookieName = 'new-quote-reference';

export const state = {
    quote: null,
    newQuote: null,
    reference: VueCookies.get(referenceCookieName),
    hasNewBooking: false,
}

export const getters = {
    quote: ({quote}) => quote,
    newQuote: ({newQuote}) => newQuote,
    hasNewBooking: ({hasNewBooking}) => hasNewBooking,
    check: ({reference}) => reference !== null,
    reference: ({reference}) => reference
}

export const mutations = {
    [types.SET_NEW_QUOTE](state, quote) {
        state.newQuote = quote;
    },

    [types.HAS_NEW_BOOKING](state, hasNewBooking) {
        state.hasNewBooking = hasNewBooking;
    },

    [types.SET_QUOTE](state, quote) {
        state.quote = quote;
    },

    [types.FETCH_QUOTE_FAILURE](state) {
        state.quote = null
    },


    [types.SAVE_REFERENCE](state, reference) {
        state.reference = reference;
        const expires = new Date();
        expires.setDate(expires.getDate() + 1);

        VueCookies.set(referenceCookieName, reference, expires)
    },

    [types.CLEAR_REFERENCE](state) {
        state.reference = null;
        VueCookies.remove(referenceCookieName)
    },

}

export const actions = {
    async dispatchNewQuote({rootGetters}, quote) {
        const {data: response} = await axios.post(POST_NEW_QUOTE, quote, {
            headers: {
                Authorization: `Bearer ${rootGetters['auth/token']}`,
                'Content-Type': 'application/json'
            }
        });

        return response;
    },

    async dispatchNewBooking({rootGetters, dispatch}, quote) {
        await axios.post(STORE_BOOKING, quote, {
            headers: {
                Authorization: `Bearer ${rootGetters['auth/token']}`,
                'Content-Type': 'application/json'
            }
        });
        dispatch('hasNewBooking', true);
    },

    async fetchNewQuote({state, rootGetters, commit}) {
        const {reference} = state;
        try {
            const {data: response} = await axios.get(`${POST_NEW_QUOTE}/${reference}`, {
                headers: {
                    Authorization: `Bearer ${rootGetters['auth/token']}`,
                    Accept: 'application/json'
                }
            });
            commit(types.SET_QUOTE, response.data);
        } catch (e) {
            commit(types.FETCH_QUOTE_FAILURE);
            commit(types.CLEAR_REFERENCE);
            throw new Error(types.FETCH_QUOTE_FAILURE);
        }

    },

    async fetchContainerOffer({state, rootGetters, commit}, container_size){
        const {reference: reference_key, quote} = state;

        const {data: response} = await axios.post(RATING_CONTAINER_OFFER, {reference_key, container_size}, {
            headers: {
                Authorization: `Bearer ${rootGetters['auth/token']}`,
                'Content-Type': 'application/json'
            }
        });

        const routes = quote.routes.concat(response.data ?? []);
        quote.routes = Array.from(new Set(routes.map(JSON.stringify))).map(JSON.parse);
        commit(types.SET_QUOTE, quote);
    },

    saveReference({commit}, reference) {
        commit(types.SAVE_REFERENCE, reference);
    },

    setQuote({commit}, quote) {
        commit(types.SET_QUOTE, quote)
    },

    setNewQuote({commit}, quote) {
        commit(types.SET_NEW_QUOTE, quote)
    },

    hasNewBooking({commit}, hasNewBooking) {
        commit(types.HAS_NEW_BOOKING, hasNewBooking)
    },

    clearQuote({dispatch, commit}) {
        commit(types.FETCH_QUOTE_FAILURE);
        commit(types.CLEAR_REFERENCE);
        dispatch('setNewQuote', null);
    },
}