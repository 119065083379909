<template>
    <aside id="app-header-wrapper" class="position-fixed d-flex flex-column">
        <div id="app-header" class="d-flex flex-lg-column">
            <Logo/>
            <NavPrimary class="mx-auto w-100"/>
            <NavSecondary class="d-none d-lg-block"/>
        </div>
    </aside>
</template>

<script>
import Logo from "@/components/Logo";
import NavPrimary from "@/components/nav/NavPrimary";
import NavSecondary from "@/components/nav/NavSecondary";

export default {
    name: "Header",
    components: {NavSecondary, Logo, NavPrimary}
}
</script>

<style lang="scss">
#app-header {
    min-height: 100%;
    @include media-breakpoint-down('md') {
        display: flex;
    }
    &-wrapper {
        left: 0;
        overflow: auto;
        @include media-breakpoint-up('lg') {
            width: 330px;
            border-right: 1px solid rgba($gray-900, 0.23);
            top: 0;
            height: 100%;
            max-height: 100%;
            @include media-breakpoint-down('xl2') {
                width: 300px;
            }
            @include media-breakpoint-down('xl') {
                width: 280px;
            }
        }

        @include media-breakpoint-down('md') {
            bottom: 0;
            width: 100%;
            border-top: 1px solid rgba($gray-900, 0.23);
            z-index: 1000;
            background-color: $body-bg;
        }


    }

    .navbar-brand-wrapper {
        @include media-breakpoint-down('md') {
            display: none !important;
        }
    }

    .navbar-brand{
        img{
            max-height: 42px !important;
        }

    }
}
</style>