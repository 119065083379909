<template>
    <Nav id="nav">
        <NavItem :to="{name: 'dashboard'}">
            <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g transform="translate(1029 -1660)">
                    <rect width="8.695" height="8.695" rx="2" transform="translate(-1029 1660)"/>
                    <rect width="8.695" height="8.695" rx="2" transform="translate(-1029 1671.304)"/>
                    <rect width="8.695" height="8.695" rx="2" transform="translate(-1017.696 1660)"/>
                    <rect width="8.695" height="8.695" rx="2" transform="translate(-1017.696 1671.304)"/>
                </g>
            </svg>
            Dashboard
        </NavItem>
        <NavItem :to="{name: 'new-quote', params: {type: 'truck'}}">
            <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="24.465"
                 viewBox="0 0 20 24.465">
                <path d="M91.532,446.606H83.386l.758,1.674h6.631Z" transform="translate(-77.459 -428.909)"/>
                <path
                    d="M74.005,371.085a.376.376,0,0,0-.311-.35l-.39-.078,0-.2-1.631-.157c-.171-1.86-.476-3.407-1.02-3.407,0,0-.007-1.8-1.1-1.8H58.629c-1.09,0-1.1,1.8-1.1,1.8-.544,0-.849,1.548-1.02,3.407l-1.631.157,0,.2-.39.078a.376.376,0,0,0-.311.35l-.088,2.761a.447.447,0,0,0,.33.437l.489.145,0,.188,1.388.657c-.006.807,0,1.334,0,1.334l-.295.276v1.8a1.1,1.1,0,0,0,1.062,1.07l.911.062a.622.622,0,0,1,.4.178l.905.909H68.9l.905-.909a.623.623,0,0,1,.4-.178l.911-.062a1.1,1.1,0,0,0,1.062-1.07v-1.8l-.295-.276s.007-.527,0-1.334l1.388-.657,0-.188.489-.145a.448.448,0,0,0,.331-.437Zm-18.859,3.372,0-.081.02-.009a.4.4,0,0,0,.244-.338l.162-2.582a.58.58,0,0,0-.246-.506l-.033-.024.006-.146,1.173-.036c-.115,1.45-.156,3.027-.169,4.181Zm13.042,5.269H60c-.609,0-1.648-2.568-1.648-2.568H69.836S68.8,379.726,68.188,379.726Zm1.465-4.288H58.533c-.763,0-.986-2.351-.986-3.874a7.778,7.778,0,0,1,.141-1.954l6.4-.146,6.4.146a7.778,7.778,0,0,1,.141,1.954C70.638,373.087,70.416,375.438,69.653,375.438Zm2.227-.522c-.013-1.154-.054-2.73-.169-4.181l1.173.036.006.146-.033.024a.58.58,0,0,0-.246.506l.162,2.582a.4.4,0,0,0,.244.338l.02.009,0,.081Z"
                    transform="translate(-54.093 -365.089)"/>
                <path
                    d="M76.2,439.262l-1.662,1.529-1.35,4.807-3.094-.017L67,445.6l-1.35-4.807-1.662-1.529-1.425-.347-.48.676v6.242l.691.64v1.284a.549.549,0,0,0,.5.585H64.5a.549.549,0,0,0,.5-.585v-1.031H75.191v1.031a.549.549,0,0,0,.5.585h1.224a.549.549,0,0,0,.5-.585v-1.284l.691-.64v-6.242l-.48-.676Zm-13.564,2.744-.046-1.393,2.143.12.459,1.983-2.06-.123A.574.574,0,0,1,62.636,442.007Zm1.082,2.808a1.086,1.086,0,0,1-.907-1.141v-.419l2.71.126L65.882,445Zm13.659-1.141a1.086,1.086,0,0,1-.907,1.141L74.305,445l.362-1.617,2.71-.126Zm.175-1.667a.574.574,0,0,1-.5.588l-2.06.123.459-1.983,2.143-.12Z"
                    transform="translate(-60.094 -423.878)"/>
                <path d="M87.03,457.664l.352.924H93.01l.352-.924H87.03Z"
                      transform="translate(-80.196 -437.961)"/>
            </svg>
            Road transport
        </NavItem>
        <NavItem :to="{name: 'new-quote', params: {type: 'air'}}">
            <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="24.449" height="24.645"
                 viewBox="0 0 24.449 24.645">
                <path class="a"
                      d="M288.694,290.942a7.164,7.164,0,0,0,1.608-3.764,1.406,1.406,0,0,0-.19-.769.62.62,0,0,0-.111-.143.656.656,0,0,0-.13-.1,1.354,1.354,0,0,0-.773-.2,7.026,7.026,0,0,0-3.733,1.62,32.256,32.256,0,0,0-3.676,3.571c-.256.271-.524.555-.808.854l-2.912-.88.008-.008a.66.66,0,0,0,0-.927l-.526-.531a.652.652,0,0,0-.463-.194.639.639,0,0,0-.461.193l-.787.793-.416-.125a.662.662,0,0,0-.052-.868l-.529-.533a.652.652,0,0,0-.463-.191.638.638,0,0,0-.459.192l-.722.728-2.962-.894a.728.728,0,0,0-.211-.03.78.78,0,0,0-.548.223l-1.041,1.05a.62.62,0,0,0-.183.433.6.6,0,0,0,.317.539l6.227,3.507a1.116,1.116,0,0,1,.152.116l1.775,1.789c-1.66,1.763-3.14,3.472-4.293,4.957-.107.138-.209.273-.31.406l-4.817-.441a.743.743,0,0,0-.083-.005.89.89,0,0,0-.6.249l-.485.489a.61.61,0,0,0-.186.433.594.594,0,0,0,.351.549l3.389,1.623a.816.816,0,0,1,.13.094l.281.281a2.082,2.082,0,0,0-.135.657.866.866,0,0,0,.236.629l.012.011,0,0a.856.856,0,0,0,.623.236,2.077,2.077,0,0,0,.647-.136l.286.287a.8.8,0,0,1,.088.128l1.607,3.409a.6.6,0,0,0,.539.358h0a.6.6,0,0,0,.429-.182l.5-.5a.9.9,0,0,0,.237-.6c0-.028,0-.049,0-.062l-.439-4.874c.132-.1.266-.2.4-.311,1.475-1.164,3.171-2.657,4.917-4.328l1.777,1.793a.959.959,0,0,1,.116.153l3.473,6.266a.6.6,0,0,0,.969.144l1.048-1.057a.806.806,0,0,0,.215-.551.766.766,0,0,0-.028-.206l-.888-2.986.722-.729a.661.661,0,0,0,0-.928l-.529-.534a.652.652,0,0,0-.862-.054l-.124-.418.788-.794a.663.663,0,0,0,.191-.465.651.651,0,0,0-.191-.464l-.526-.531a.656.656,0,0,0-.464-.194.645.645,0,0,0-.461.193l-.006.006-.873-2.936q.452-.435.861-.825a32.1,32.1,0,0,0,3.53-3.693Z"
                      transform="translate(-265.852 -285.966)"/>
            </svg>
            Air freight
        </NavItem>

        <NavItem :to="{name: 'new-quote', params: {type: 'sea'}}"
        >
            <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="35"
                 height="16.966" viewBox="0 0 35 16.966">
                <g>
                    <path
                        d="M3.238,16.964.081,12.231a.99.99,0,0,1,.52-1.3.981.981,0,0,1,.389-.081h7.77l1.183,1.183h17.17l3.747-2.169H35l-3.551,5.129s.986.2.986,1.974Zm19.728-5.917V8.284h5.129v1.974l-1.185.792ZM17.048,8.284h5.129v2.762H17.048Zm-6.117,0h5.131v2.762H10.932ZM2.053,9.861V1.777H4.225V1.185L7.183,0V1.775H8.366V2.958H3.04v.988H8.366V5.129H7.183V9.863ZM22.964,4.535h5.129V7.3H22.964Zm-5.917,0h5.129V7.3H17.046Zm-6.117,0h5.132V7.3H10.93Z"
                        transform="translate(0 0)"/>
                </g>
            </svg>
            Sea freight
        </NavItem>


<!--        <NavItem to="#"-->
<!--                 class="disabled"-->
<!--                 :disabled="true"-->
<!--                 :event="''"-->
<!--        >-->
<!--            <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="23.957" height="20.282"-->
<!--                 viewBox="0 0 23.957 20.282">-->
<!--                <g transform="translate(-5 1.24)">-->
<!--                    <g transform="translate(5 -1.24)">-->
<!--                        <path class="a"-->
<!--                              d="M638.136,374.788a1.058,1.058,0,0,0-1.414-.488l-8.083,3.938a3.169,3.169,0,0,0-2.755-1.031l-5.7-12.6-4.56-1.253a1.058,1.058,0,0,0-.561,2.04l3.6.99,5.316,11.749a3.209,3.209,0,1,0,5.491,2.256c0-.068-.006-.135-.01-.2l8.185-3.988A1.058,1.058,0,0,0,638.136,374.788Z"-->
<!--                              transform="translate(-614.286 -363.319)"/>-->
<!--                        <rect width="8.813" height="7.923"-->
<!--                              transform="matrix(0.911, -0.412, 0.412, 0.911, 9.981, 6.201)"/>-->
<!--                    </g>-->
<!--                </g>-->
<!--            </svg>-->
<!--            Warehouse-->
<!--        </NavItem>-->

        <NavItem :to="{name: 'settings'}" class="d-lg-none">
            <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path
                    d="M2025.594,11a6.261,6.261,0,0,0,.051-1c0-.35-.051-.65-.051-1l2.147-1.65a.459.459,0,0,0,.1-.65L2025.8,3.25a.5.5,0,0,0-.614-.2l-2.557,1a7.443,7.443,0,0,0-1.738-1L2020.531.4a.548.548,0,0,0-.511-.4h-4.091a.548.548,0,0,0-.511.4l-.409,2.65a8.658,8.658,0,0,0-1.739,1l-2.557-1a.479.479,0,0,0-.614.2l-2.046,3.45a.6.6,0,0,0,.1.65l2.2,1.65c0,.35-.051.65-.051,1s.051.65.051,1l-2.147,1.65a.459.459,0,0,0-.1.65l2.046,3.45a.5.5,0,0,0,.614.2l2.557-1a7.444,7.444,0,0,0,1.739,1l.409,2.65a.5.5,0,0,0,.511.4h4.091a.548.548,0,0,0,.511-.4l.41-2.65a8.658,8.658,0,0,0,1.738-1l2.557,1a.479.479,0,0,0,.614-.2l2.046-3.45a.6.6,0,0,0-.1-.65Zm-7.62,2.5a3.5,3.5,0,1,1,3.58-3.5A3.518,3.518,0,0,1,2017.974,13.5Z"
                    transform="translate(-2008 0)"/>
            </svg>
            <span class="d-none d-lg-inline">Account</span> Settings
        </NavItem>
        <li class="d-lg-none nav-item nav-item-logout">
            <a href="#" @click.prevent="handleLogout" class="nav-link position-relative">
                <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="20.5" height="20.5"
                     viewBox="0 0 20.5 20.5">
                    <g transform="translate(-3.25 -3.25)">
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                              d="M9.33,22.5H6.11a1.837,1.837,0,0,1-1.61-2V6.5a1.837,1.837,0,0,1,1.61-2H9.33"/>
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                              d="M24,18.55l4.025-4.025L24,10.5" transform="translate(-5.525 -1.025)"/>
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                              d="M23.16,18H13.5" transform="translate(-0.66 -4.5)"/>
                    </g>
                </svg>
                Logout
            </a>
        </li>
    </Nav>
</template>

<script>
import NavItem from "@/components/nav/NavItem";
import Nav from "@/components/nav/Nav";
import logout from "@/mixins/logout";

export default {
    name: "NavPrimary",
    components: {Nav, NavItem},
    mixins: [logout],
    methods: {
        async handleLogout(){
            await this.logout();
        }
    }
}
</script>

<style lang="scss">
#app-nav {
    @include media-breakpoint-up('lg'){
        margin-top: 4.5rem;
    }

    .nav-item-icon {
        transition: stroke 0.3s ease,
        fill 0.3s ease;
    }
}
</style>