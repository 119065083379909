import store from '@/store'
import VueCookies from 'vue-cookies'
import {guest_route, logout_route} from "@/config/auth";

export default async (to, from, next) => {
    if (!store.getters['auth/check']) {

        if(to.name !== logout_route){
            VueCookies.set('intended_url', to.path)
        }

        next({ name: guest_route })
    } else {
        next()
    }
}