import {GET_BALANCE_HISTORY_URL, GET_BOOKINGS} from '@/config/api'
import * as types from "@/store/mutationTypes";
import axios from "axios";
import md5 from 'md5';

export const state = {
    balanceHistory: [],
    bookings: [],
}

export const getters = {

}

export const mutations = {

    [types.FETCH_USER_BALANCE_HISTORY](state, {page,response}) {
        state.balanceHistory[page] = response
    },

    [types.CLEAR_USER_BALANCE_HISTORY](state) {
        state.balanceHistory = [];
    },

    [types.FETCH_USER_BOOKINGS](state, {params,response}) {
        const key = md5(JSON.stringify(params));
        state.bookings[key] = response
    },

    [types.CLEAR_USER_BOOKINGS](state) {
        state.bookings = [];
    },
}

export const actions = {
    async fetchBalanceHistory({rootGetters, commit, state}, params){
        params.page = params.page || 1;

        if(state.balanceHistory[params.page]){
            return state.balanceHistory[params.page];
        }

        const {data: response} = await axios.get(GET_BALANCE_HISTORY_URL, {
            params,
            headers: {
                Authorization: `Bearer ${rootGetters['auth/token']}`,
                'Content-Type': 'application/json'
            }
        });
        commit(types.FETCH_USER_BALANCE_HISTORY, {
            page: params.page,
            response
        })
        return response;
    },

    clearStoredBalanceHistory({commit}){
        commit(types.CLEAR_USER_BALANCE_HISTORY);
    },


    async fetchBookings({rootGetters,  state}, params){
        params.page = params.page || 1;

        const key = md5(JSON.stringify(params));

        if(state.bookings[key]){
            return state.bookings[key];
        }

        const {data: response} = await axios.get(GET_BOOKINGS, {
            params,
            headers: {
                Authorization: `Bearer ${rootGetters['auth/token']}`,
                'Content-Type': 'application/json'
            }
        });
        // commit(types.FETCH_USER_BOOKINGS, {
        //     params,
        //     response
        // })
        return response;
    },

    clearStoredBookings({commit}){
        commit(types.CLEAR_USER_BOOKINGS);
    }
}