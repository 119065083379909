<template>
    <div id="app">
        <Preloader ref="preloader"/>
        <router-view/>
        <FixedAlert :message="message" theme="messageTheme"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import FixedAlert from "@/components/notices/FixedAlert";
import Preloader from "@/components/Preloader";


export default {
    name: 'App',
    components: {FixedAlert, Preloader},
    mounted() {
        this.$preloader = this.$refs.preloader
    },
    computed: {
        ...mapGetters({
            message: 'serverNotice/message',
            messageTheme: 'serverNotice/type'
        })
    }
}
</script>

<style lang="scss">
@import "~@/assets/scss/fonts";
@import "~bootstrap/scss/root";
@import "~@/assets/scss/reboot";
@import "~@/assets/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~@/assets/scss/components/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~@/assets/scss/components/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~@/assets/scss/components/custom-forms";
@import "~bootstrap/scss/nav";
@import "~@/assets/scss/components/navbar";
@import "~@/assets/scss/components/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~@/assets/scss/utilities";
@import "~bootstrap/scss/print";
@import "~@/assets/scss/components/recaptcha";
@import "~@/assets/scss/components/tidio-chat";

#app {
    margin-left: auto;
    margin-right: auto;
}
</style>
