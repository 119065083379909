<template>
    <div>
        <Header/>
        <main id="app-content" class="position-relative">
            <header id="app-content-header"
                    class="w-100 py-md-3 py-lg-4">
                <div class="container d-flex">
                    <Logo class="d-lg-none"/>
<!--                    <Search />-->
                    <NavUser />
                </div>
            </header>
            <section class="container-lg">
                <slot/>
            </section>
        </main>
    </div>
</template>

<script>
import Header from "@/shared/global/Header";
// import Search from "@/components/Search";
import NavUser from "@/components/nav/NavUser";
import Logo from "@/components/Logo";
export default {
    name: "PrivateLayout",
    components: {Logo, NavUser, Header},
}
</script>

<style lang="scss">
#app-new-quote-btn{
    @include media-breakpoint-down('md'){
        width: 100% !important;
    }
}
#app-content {
    min-height: 100%;
    margin-left: 330px;
    padding-top: 95px;
    padding-bottom: 95px;

    @include media-breakpoint-down('xl2'){
        margin-left: 300px;
    }
    @include media-breakpoint-down('xl'){
        margin-left: 280px;
    }
    @include media-breakpoint-down('md'){
        margin-left: 0px;
        padding-top: 76px;
    }
    &-header{
        top:0;
        position: absolute;
        @include media-breakpoint-down('md'){
            position: fixed;
            left:0;
            z-index: 1000;
            background-color: $body-bg;
            box-shadow: $box-shadow-sm;
        }
        @include media-breakpoint-down('sm'){
            padding-top: 10px;
            padding-bottom: 10px;
        }
        >.container{
            @include media-breakpoint-down('md'){
                max-width: 100%;
            }
        }

        .navbar-brand{
            &-wrapper{
                padding: 0 15px 0 0 !important;
            }
            img{
                max-height: 44px !important;
            }
        }
    }
}
</style>