import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta';
import VueTidio from 'vue-tidio';

import axios from 'axios'
import router from './router'
import store from './store'
import './interceptors'

import App from './App.vue'

import PublicLayout from "@/layouts/PublicLayout";
import PrivateLayout from "@/layouts/PrivateLayout";


import {CHAT_KEY as appKey} from '@/config/chat'

Vue.config.productionTip = false

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(VueTidio, { appKey });
Vue.use(VueMeta, {
  keyName: 'meta',
});

Vue.component('PublicLayout', PublicLayout);
Vue.component('PrivateLayout', PrivateLayout);

new Vue({
  router,
  store,
  ...App
}).$mount('#app');