import {guest_route} from "@/config/auth";

function page (path) {
    return () => import(
        `../pages/${path}.vue`
        ).then(m => m.default || m)
}

const routes = [
    {
        path: '/dashboard/:page?',
        name: 'dashboard',
        component: page('Dashboard'),
    },
    {
        path: '/settings/:tab?/:page?',
        name: 'settings',
        component: page('Settings'),
    },
    {
        path: '/new-quote/:type?',
        name: 'new-quote',
        component: page('checkout/NewQuote'),
    },
    {
        path: '/quote-summary',
        name: 'quote-summary',
        component: page('checkout/QuoteSummary'),
    },
    {
        path: '/login',
        name: 'login',
        component: page('auth/Login'),
    },
    {
        path: '/register',
        name: 'register',
        component: page('auth/Register'),
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: page('auth/ForgotPassword'),
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password',
        component: page('auth/ResetPassword'),
    },
    {
        path: '/email/verify/:id/:hash',
        name: 'email-verification',
        component: page('auth/EmailVerification'),
    },
    { path: '/', redirect: { name: guest_route }},


    { path: '*', component: page('errors/Error404') }
]

export default routes