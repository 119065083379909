import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from  '@/store';

Vue.use(Router)

const globalMiddleware = ['check-auth'];

const routeMiddleware = resolveMiddleware(
    require.context('@/middleware', false, /.*\.js$/)
)


function createRouter() {
    const router = new Router({
        scrollBehavior,
        mode: 'history',
        routes
    })

    router.beforeEach(beforeEach)
    router.afterEach(afterEach)

    return router
}


const router = createRouter();

export default router


async function beforeEach (to, from, next) {
    store.dispatch('serverNotice/clearErrors');

    if(router.app.$preloader){
        router.app.$nextTick(() => router.app.$preloader.start())
    }

    let components = []

    try {
        // Get the matched components and resolve them.
        components = await resolveComponents(
            router.getMatchedComponents({ ...to })
        )
    } catch (error) {
        if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
            window.location.reload(true)
            return
        }
    }

    if (components.length === 0) {
        return next()
    }

    // Get the middleware for all the matched components.
    const middleware = getMiddleware(components)

    // Call each middleware.
    callMiddleware(middleware, to, from, (...args) => {
        // Set the application layout only if "next()" was called with no args.
        if (args.length === 0) {
            // router.app.setLayout(components[0].layout || '')
        }

        next(...args)
    })
}

// async function afterEach (to, from, next) {
async function afterEach () {
    await router.app.$nextTick()
    router.app.$preloader.finish();
}

function callMiddleware (middleware, to, from, next) {
    const stack = middleware.reverse()

    const _next = (...args) => {
        // Stop if "_next" was called with an argument or the stack is empty.
        if (args.length > 0 || stack.length === 0) {
            if (args.length > 0) {
                router.app.$preloader.start()
            }

            return next(...args)
        }

        const { middleware, params } = parseMiddleware(stack.pop())

        if (typeof middleware === 'function') {
            middleware(to, from, _next, params)
        } else if (routeMiddleware[middleware]) {
            routeMiddleware[middleware](to, from, _next, params)
        } else {
            throw Error(`Undefined middleware [${middleware}]`)
        }
    }

    _next()
}



function parseMiddleware (middleware) {
    if (typeof middleware === 'function') {
        return { middleware }
    }

    const [name, params] = middleware.split(':')

    return { middleware: name, params }
}


function resolveComponents (components) {
    return Promise.all(components.map(component => {
        return typeof component === 'function' ? component() : component
    }))
}


function getMiddleware(components) {
    const middleware = [...globalMiddleware]

    components.filter(c => c.middleware).forEach(component => {
        if (Array.isArray(component.middleware)) {
            middleware.push(...component.middleware)
        } else {
            middleware.push(component.middleware)
        }
    })

    return middleware
}


function scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    }

    if (to.hash) {
        return {selector: to.hash}
    }

    const [component] = router.getMatchedComponents({...to}).slice(-1)

    if (component && component.scrollToTop === false) {
        return {}
    }

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({x: 0, y: 0})
        }, 190)
    })
}


function resolveMiddleware(requireContext) {
    return requireContext.keys()
        .map(file =>
            [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
        )
        .reduce((guards, [name, guard]) => (
            {...guards, [name]: guard.default}
        ), {})
}