
// export const API_URL = 'https://api.gepax.us/api'; // production
export const API_URL = 'https://api.gepax.w03.pl/api';
// export const API_URL = 'http://gepax-api.test/api'; // development

export const API_WP_URL = 'https://gepalogistics.com/wp-json/wp/v2';

export const LOGIN_URL = `${API_URL}/auth/login`;
export const REGISTER_URL =  `${API_URL}/auth/register`;
export const LOGOUT_URL =  `${API_URL}/auth/logout`;
export const VERIFY_EMAIL_URL =  `${API_URL}/auth/email/verify/:id/:hash`;

export const AUTH_USER_URL =  `${API_URL}/auth/user`;
export const EDIT_USER_URL =  `${API_URL}/auth/user/edit`;
export const CHANGE_PASSWORD_USER_URL =  `${API_URL}/auth/user/change/password`;
export const CHANGE_SETTINGS_USER_URL =  `${API_URL}/auth/user/update/settings`;
export const CHANGE_CURRENCY_USER_URL =  `${API_URL}/auth/user/update/currency`;
export const GET_BALANCE_HISTORY_URL =  `${API_URL}/auth/user/balance-history`;

export const FORGOT_PASSWORD_URL = `${API_URL}/auth/forgot-password`;
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`;

export const DIRECTION_URL =  `${API_URL}/v1/direction`;

export const POST_NEW_QUOTE =  `${API_URL}/v1/rating`;
export const RATING_CONTAINER_OFFER =  `${API_URL}/v1/rating/type/fcl`;
export const STORE_BOOKING =  `${API_URL}/v1/booking`;
export const GET_BOOKINGS =  `${API_URL}/v1/bookings`;

export const CURRENCY_URL =  `${API_URL}/v1/currencies`;
export const UNIT_URL =  `${API_URL}/v1/units`;
export const VEHICLE_URL =  `${API_URL}/v1/vehicles`;
export const CUSTOMS_CLEARANCE_URL =  `${API_URL}/v1/customs-clearance`;


export const PRIVACY_POLICY_URL = `${API_WP_URL}/pages/1468`
export const TERMS_AND_CONDITIONS_URL = `${API_WP_URL}/pages/1470`
export const INSURANCE_RULES_URL = `${API_WP_URL}/pages/1474`
