<template>
    <main id="app-content">
        <section class="container-lg">
            <slot/>
        </section>
    </main>
</template>

<script>

export default {
    name: "PublicLayout",
}
</script>

<style scoped lang="scss">
#app-content {
    min-height: 100%;
    overflow: auto;
    width: 100%;
    position: absolute;
    max-height: 100%;
    padding-top: 95px;
    padding-bottom: 95px;
}
</style>