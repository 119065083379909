<template>
    <li class="nav-item">
        <router-link :to="to" v-on="$listeners" class="nav-link position-relative"
                     :class="{
                        disabled
                     }"
                     :disabled="disabled">
            <slot/>
        </router-link>
    </li>
</template>

<script>
export default {
    name: "NavItem",
    props: {
        icon: String,
        to: [Object, String],
        disabled: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss">

.nav {

    &-link{
        &.disabled{
            opacity: 0.3;
        }
    }
    &-item {
        @include media-breakpoint-up('lg') {
            margin-bottom: 40px;
            @include media-breakpoint-down('xl2') {
                margin-bottom: 35px;
            }
        }

        &-logout{
            .nav-item-icon {
                stroke: $gray-600;
                transition: stroke 0.3s ease,
                fill 0.3s ease;
            }

            &:hover, &:focus, &.router-link-active {
                .nav-item-icon {
                    stroke: $primary;
                }
            }
        }

        &-icon, svg {
            fill: $gray-600;
            position: absolute;
            transition: 0.3s ease fill;
            @include media-breakpoint-up('lg') {
                top: 50%;
                transform: translateY(-50%);
                left: 70px;
                @include media-breakpoint-down('xl2') {
                    left: 70px;
                }
                @include media-breakpoint-down('xl') {
                    left: 50px;
                }
            }
            @include media-breakpoint-down('md') {
                top: 15px;
                transform: translateX(-50%);
                left: 50%;
            }
            @include media-breakpoint-down('sm') {
                top: 10px;
                width: 20px;
                height: 20px;
            }
        }
    }

    &-link {
        color: $gray-600;
        font-size: $nav-link-font-size;
        border-left: 0px solid $primary;

        @include media-breakpoint-up('lg') {
            padding-left: 130px !important;
            @include media-breakpoint-down('xl2') {
                font-size: $nav-link-font-size * .9;
                padding-left: 110px !important;
            }
            @include media-breakpoint-down('xl') {
                padding-left: 100px !important;
            }
        }
        @include media-breakpoint-down('md') {
            padding-left: 10px !important;
            padding-right: 10px !important;
            padding-bottom: 10px;
            padding-top: 45px;
            font-size: $nav-link-font-size * .8;
            width: 120px;
            white-space: nowrap;
            display: flex;
            justify-content: center;
        }
        @include media-breakpoint-down('sm') {
            padding-top: 35px;
            font-size: $nav-link-font-size * .65;
            width: 80px;
        }

        &:before {
            content: "";
            transition: width 0.3s ease,
            height 0.3s ease;
            position: absolute;
            left: 0;
            background-color: $primary;

            @include media-breakpoint-up('lg') {
                top: 0;
                width: 0;
                height: 100%;
            }
            @include media-breakpoint-down('md') {
                bottom: 0;
                width: 100%;
                height: 0;
            }
        }

        &:hover, &:focus, &.router-link-active {
            color: $gray-600;

            .nav-item-icon {
                fill: $primary;
            }
        }

        &.router-link-active {
            pointer-events: none;

            &:before {
                @include media-breakpoint-up('lg') {
                    width: 3px;
                }
                @include media-breakpoint-down('md') {
                    height: 3px;
                }
            }
        }
    }
}
</style>