<template>
    <div v-if="user"
        id="app-nav-user"
         class="text-right ml-auto d-flex d-lg-block align-items-center">
        <Dropdown class="order-1 order-lg-0"
                  btn-class-name="btn-link border-none text-dark h5 d-flex align-items-center my-auto py-2 pr-1 pl-3 pl-md-2"
                  menu-class-name="dropdown-menu-right dropdown-menu-lg-center"
        >
            <template v-slot:dropdown-btn-label>
                <span class="d-none d-lg-inline">{{ user.first_name }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="d-inline-block d-lg-none" viewBox="0 0 16 16">
                    <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
                    <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="dropdown-arrow d-none d-lg-inline ml-2 mr-1"
                     viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
            </template>
            <template v-slot:dropdown-menu>
                <router-link :to="{name: 'settings'}" class="dropdown-item">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                         viewBox="0 0 20 20">
                        <path
                            d="M2025.594,11a6.261,6.261,0,0,0,.051-1c0-.35-.051-.65-.051-1l2.147-1.65a.459.459,0,0,0,.1-.65L2025.8,3.25a.5.5,0,0,0-.614-.2l-2.557,1a7.443,7.443,0,0,0-1.738-1L2020.531.4a.548.548,0,0,0-.511-.4h-4.091a.548.548,0,0,0-.511.4l-.409,2.65a8.658,8.658,0,0,0-1.739,1l-2.557-1a.479.479,0,0,0-.614.2l-2.046,3.45a.6.6,0,0,0,.1.65l2.2,1.65c0,.35-.051.65-.051,1s.051.65.051,1l-2.147,1.65a.459.459,0,0,0-.1.65l2.046,3.45a.5.5,0,0,0,.614.2l2.557-1a7.444,7.444,0,0,0,1.739,1l.409,2.65a.5.5,0,0,0,.511.4h4.091a.548.548,0,0,0,.511-.4l.41-2.65a8.658,8.658,0,0,0,1.738-1l2.557,1a.479.479,0,0,0,.614-.2l2.046-3.45a.6.6,0,0,0-.1-.65Zm-7.62,2.5a3.5,3.5,0,1,1,3.58-3.5A3.518,3.518,0,0,1,2017.974,13.5Z"
                            transform="translate(-2008 0)"/>
                    </svg>
                    Account settings
                </router-link>
                <div class="dropdown-divider"></div>
                <a href="#" @click.prevent="logout" class="dropdown-item">
                    <svg class="dropdown-icon-logout" xmlns="http://www.w3.org/2000/svg" width="20.5" height="20.5"
                         viewBox="0 0 20.5 20.5">
                        <g transform="translate(-3.25 -3.25)">
                            <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                                  d="M9.33,22.5H6.11a1.837,1.837,0,0,1-1.61-2V6.5a1.837,1.837,0,0,1,1.61-2H9.33"></path>
                            <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                                  d="M24,18.55l4.025-4.025L24,10.5" transform="translate(-5.525 -1.025)"></path>
                            <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                                  d="M23.16,18H13.5" transform="translate(-0.66 -4.5)"></path>
                        </g>
                    </svg>
                    Logout
                </a>
            </template>
        </Dropdown>
        <div><span class="d-none d-lg-inline-block mr-1">Your credit balance: </span><strong>{{ user.balance.converted.formatted }}</strong></div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Dropdown from "@/components/Dropdown";
import logout from "@/mixins/logout";

export default {
    name: "NavUser",
    components: {Dropdown},
    mixins: [logout],
    computed: {
        ...mapGetters({
            'user': 'auth/user'
        }),
    },
}
</script>

<style lang="scss">

#app-nav-user {
    .btn-link {
        font-size: $h5-font-size;
        font-weight: $font-weight-medium;
        text-decoration: none;

        &:focus {
            outline: none !important;
            box-shadow: none;
        }

        &.show {
            .dropdown-arrow {
                transform: rotate(-180deg);
            }
        }

        svg {
            transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;

            @include media-breakpoint-down('md'){
                width: 28px;
                height: 28px;
                fill: $gray-600;
            }
        }
    }

    .dropdown {
        &-menu {
            top: calc(100% + 5px);

            &:before, &:after {
                content: '';
                display: block;
                position: absolute;

                width: 0;
                height: 0;
                border-style: solid;
                transform: translate(-50%, -100%);
                @include media-breakpoint-up('lg'){
                    left: 50%;
                    transform: translate(-50%, -100%);
                }
                @include media-breakpoint-down('md'){
                    left: calc(100% - 18px);
                    transform: translate(-50%, -100%);
                }
            }

            &:after {
                top: 0;
                border-color: transparent transparent $dropdown-bg transparent;
                border-width: 9px;
            }

            &:before {
                top: 0;
                border-color: transparent transparent rgb(205, 205, 205) transparent;
                border-width: 10px;
            }
        }

        &-item {
            position: relative;
            padding-left: 50px;

            &:active {

                .dropdown-icon-logout{
                    stroke: $white;
                }
                svg {
                    fill: $white;
                }
            }

            svg {
                position: absolute;
                fill: $gray-600;
                pointer-events: none;
                left: 15px;
                width: 15px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        &-icon {
            &-logout {
                stroke: $gray-600;
            }
        }
    }
}
</style>