<template>
    <div v-if="show" class="preloader">
        <div/>
    </div>
</template>

<script>
export default {
    name: "Preloader",
    data: () => ({
        show: false
    }),
    methods: {
        start() {
            this.show = true;
        },
        finish() {
            setTimeout(() => {
                this.show = false;
            }, 250)
        }
    }
};
</script>

<style scoped lang="scss">
.preloader {
    background-color: rgba($white, 1);
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;

    div {
        display: block;
        position: relative;
        left: 50%;
        top: 50%;
        width: 150px;
        height: 150px;
        margin: -75px 0 0 -75px;

        &,
        &:before,
        &:after {
            content: "";
            position: absolute;
            border: 3px solid transparent;
            border-radius: 50%;
        }

        & {
            border-top-color: $primary;
            animation: spinner-border 1s linear infinite;
        }

        &:before {
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
            border-top-color: lighten($primary, 10);
            animation: spinner-border 6s linear infinite;
        }

        &:after {
            top: 15px;
            left: 15px;
            right: 15px;
            bottom: 15px;
            border-top-color: lighten($primary, 20);
            animation: spinner-border 3s linear infinite;
        }
    }
}
</style>
