import store from '@/store'

export default async (from, to, next) => {
    if(store.getters['checkout/check'] && store.getters['checkout/reference']){
        try {
            const quote = store.getters['checkout/quote'];

            if(!quote){
                await store.dispatch('checkout/fetchNewQuote')
            }

            next();
        } catch (e) {
            next({name: 'new-quote'})
        }
    }  else{
        next({name: 'new-quote'})
    }
}