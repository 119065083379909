<template>
    <div class="btn-group"
         ref="wrapper"
         :class="{show}">
        <button type="button"
                class="btn"
                :class="{[btnClassName]: btnClassName, show}"
                @click="show = !show"
        >
            <slot name="dropdown-btn-label">Dropdown button</slot>
        </button>
        <transition name="fade">
            <div v-if="show"
                 class="dropdown-menu show"
                 :class="menuClassName"
                 @click="show = !show">
                <slot name="dropdown-menu">Dropdown Menu</slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Dropdown",
    data: () => ({
        show: false,
    }),
    props: {
        menuClassName: String,
        btnClassName: String,
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    watch: {
        show(value) {
            if (value) {
                this.$emit('dropdownOpen');
            } else this.$emit('dropdownClose');
        }
    },
    methods: {
        handleClickOutside(e) {
            if (!e || !e.target || !this.$refs.wrapper) return;
            if (!this.$refs.wrapper.contains(e.target) && this.show) {
                this.show = false;
            }
        }
    }
}
</script>

<style lang="scss">

.dropdown {
    &-menu {
        transform-origin: left center;

        @each $breakpoint in map-keys($grid-breakpoints) {
            @include media-breakpoint-up($breakpoint) {
                $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
                &.dropdown-menu#{$infix}-right {
                    transform-origin: right center;
                }
                &.dropdown-menu#{$infix}-center {
                    left: 50%;
                    transform: translateX(-50%);
                    transform-origin: left center;
                    right: auto;
                }
            }
        }
    }
}

.fade-leave-active,
.fade-enter-active {
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: scale(0.75) translateY(-19px);

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            &.dropdown-menu#{$infix}-center {
                transform: scale(0.75) translateY(-19px) translateX(-50%);
            }
        }
    }
}

.fade-leave, .fade-enter-to {
    opacity: 1;
    transform: scale(1) translateY(0);

    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            &.dropdown-menu#{$infix}-center {
                transform: scale(1) translateY(0) translateX(-50%);
            }
        }
    }
}
</style>