import * as types from '../mutationTypes'

export const state = {
    errors: {},
    message: null,
    type: 'danger'
}
export const getters = {
    errors: ({errors}) => errors,
    message: ({message}) => message,
    type: ({type}) => type,
}

export const mutations = {
    [types.SET_ERRORS] (state, errors) {
        for (const [key, value] of Object.entries(errors)) {
            errors[key] = value.pop()
        }
        state.errors = errors;
    },
    [types.SET_MESSAGE] (state, message) {
        state.message = message;
    },
    [types.SET_TYPE] (state, status) {
        state.type = status >= 400 ? 'danger' : 'success';
    }
}

export const actions = {
    setNotice ({ commit }, {errors = {}, message, status}) {
        commit(types.SET_ERRORS, errors)
        commit(types.SET_MESSAGE, message)
        commit(types.SET_TYPE, status)
    },
    setErrors ({ commit }, errors) {
        commit(types.SET_ERRORS, errors)
    },
    setMessage ({ commit }, message) {
        commit(types.SET_MESSAGE, message)
    },
    setType ({ commit }, message) {
        commit(types.SET_MESSAGE, message)
    },
    clearErrors({dispatch}){
        dispatch('setErrors',  {});
    },
    clearNotice({dispatch}){
        dispatch('setNotice', {
            errors: {},
            message: null,
            type: 'danger'
        });
    }
}