import * as types from "@/store/mutationTypes";
import {INSURANCE_RULES_URL, PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL} from "@/config/api";
import axios from "axios";

const headers = {
    'Content-Type': 'application/json'
}

export const state = {
    privacyPolicy: null,
    termsAndConditions: null,
    insurance: null
}

export const getters = {
    privacyPolicy: ({privacyPolicy}) => privacyPolicy,
    termsAndConditions: ({termsAndConditions}) => termsAndConditions,
    insurance: ({insurance}) => insurance,
}

export const mutations = {

    [types.FETCH_PRIVACY_POLICY](state, {response}) {
        state.privacyPolicy = response
    },

    [types.FETCH_TERMS_AND_CONDITIONS](state, {response}) {
        state.termsAndConditions = response
    },

    [types.FETCH_INSURANCE_RULES](state, {response}) {
        state.insurance = response
    }
}


export const actions = {
    async fetchPrivacyPolicy({commit, state}) {
        if (state.privacyPolicy)
            return state.privacyPolicy;

        const {data: response} = await axios.get(PRIVACY_POLICY_URL, {headers});
        commit(types.FETCH_PRIVACY_POLICY, {
            response
        })
        return response;
    },

    async fetchTermsAndConditions({commit, state}) {
        if (state.termsAndConditions)
            return state.termsAndConditions;

        const {data: response} = await axios.get(TERMS_AND_CONDITIONS_URL, {headers});
        commit(types.FETCH_TERMS_AND_CONDITIONS, {
            response
        })
        return response;
    },

    async fetchInsurance({commit, state}) {
        if (state.insurance)
            return state.insurance;

        const {data: response} = await axios.get(INSURANCE_RULES_URL, {headers});
        commit(types.FETCH_INSURANCE_RULES, {
            response
        })
        return response;
    },
}
