import * as types from "@/store/mutationTypes";
import {CURRENCY_URL, UNIT_URL, VEHICLE_URL, CUSTOMS_CLEARANCE_URL} from "@/config/api";
import axios from "axios";

export const state = {
    units: null,
    vehicles: null,
    currencies: null,
    customsClearance: null,
    containers: null,
}

// getters
export const getters = {
    units: state => state.units,
    vehicles: state => state.vehicles,
    containers: state => state.containers,
    currencies: state => state.currencies,
    customsClearance: state => state.customsClearance,
}

export const mutations = {
    [types.FETCH_UNITS_SUCCESS](state, units) {
        state.units = units
    },

    [types.FETCH_UNITS_FAILURE](state) {
        state.units = null;
    },

    [types.FETCH_CURRENCIES_SUCCESS](state, currencies) {
        state.currencies = currencies
    },

    [types.FETCH_CURRENCIES_FAILURE](state) {
        state.currencies = null;
    },

    [types.FETCH_VEHICLES_SUCCESS](state, vehicles) {
        state.vehicles = vehicles
    },

    [types.FETCH_VEHICLES_FAILURE](state) {
        state.vehicles = null;
        state.containers = null;
    },

    [types.FETCH_CONTAINERS_SUCCESS](state, containers) {
        state.containers = containers;
    },

    [types.FETCH_CUSTOMS_CLEARANCE_SUCCESS](state, customsClearance) {
        state.customsClearance = customsClearance
    },

    [types.FETCH_CUSTOMS_CLEARANCE_FAILURE](state) {
        state.customsClearance = null;
    },
}

export const actions = {

    async fetchUnits({commit, state}) {
        if(state.units) return;

        try {
            const {data: response} = await axios.get(UNIT_URL);

            commit(types.FETCH_UNITS_SUCCESS, response.data.unit);
        } catch (e) {
            commit(types.FETCH_UNITS_FAILURE)
        }
    },

    async fetchCurrencies({state, commit}) {
        if(state.currencies) return;

        try {
            const {data: response} = await axios.get(CURRENCY_URL);

            commit(types.FETCH_CURRENCIES_SUCCESS, response.data.currency);
        } catch (e) {
            commit(types.FETCH_CURRENCIES_FAILURE)
        }
    },


    async fetchVehicles({state, commit, rootGetters}) {
        if(state.vehicles) return;

        try {
            const {data: response} = await axios.get(VEHICLE_URL, {
                headers: {Authorization: `Bearer ${rootGetters['auth/token']}`}
            });

            commit(types.FETCH_VEHICLES_SUCCESS, response.data.vehicle);
            commit(types.FETCH_CONTAINERS_SUCCESS, response.data.container);
        } catch (e) {
            commit(types.FETCH_VEHICLES_FAILURE)
        }
    },


    async fetchCustomsClearance({state, commit, rootGetters}) {
        if(state.customsClearance) return;

        try {
            const {data: response} = await axios.get(CUSTOMS_CLEARANCE_URL, {
                headers: {Authorization: `Bearer ${rootGetters['auth/token']}`}
            });

            commit(types.FETCH_CUSTOMS_CLEARANCE_SUCCESS, response.data.customs_clearance);
        } catch (e) {
            commit(types.FETCH_CUSTOMS_CLEARANCE_FAILURE)
        }
    }
}