import store from "@/store";

export default async (to, from, next) => {
    const token = to.params.token || null,
        email = to.query.email || null;

    try {
        await store.dispatch('auth/checkResetPassword', {token, email})
        next();
    } catch (e) {
        store.dispatch('serverNotice/setNotice', {
            status: 419,
            message: 'Your link has expired. Complete the form again.',
        });
        next({name: 'forgot-password'});
    }
}