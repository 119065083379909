<template>
    <figure class="navbar-brand-wrapper d-flex justify-content-center text-uppercase py-4 m-0">
        <router-link :to="{name: url}" class="navbar-brand m-0 font-weight-bold">
            <img class="img-fluid" src="@/assets/img/gepax_platform_logo.png">
            <span class="sr-only">{{ name }}</span>
        </router-link>
    </figure>

</template>

<script>
import {name} from '@/config/app';
import {auth_route, guest_route} from "@/config/auth";

export default {
    name: "Logo",
    data: () => ({
        name,
    }),
    computed: {
        url() {
            return this.$store.getters['auth/check'] ? auth_route : guest_route;
        }
    }
}
</script>

<style scoped lang="scss">
.navbar-brand {
    font-size: $font-size-base * 1.35;

    img{
        max-height: 75px;

        @include media-breakpoint-down('sm'){
            max-height: 65px;
        }

        @include media-breakpoint-down('sm'){
            max-height: 55px;
        }
    }
}
</style>