<template>
    <Nav id="nav-secondary">
        <NavItem :to="{name: 'settings'}">
            <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path
                    d="M2025.594,11a6.261,6.261,0,0,0,.051-1c0-.35-.051-.65-.051-1l2.147-1.65a.459.459,0,0,0,.1-.65L2025.8,3.25a.5.5,0,0,0-.614-.2l-2.557,1a7.443,7.443,0,0,0-1.738-1L2020.531.4a.548.548,0,0,0-.511-.4h-4.091a.548.548,0,0,0-.511.4l-.409,2.65a8.658,8.658,0,0,0-1.739,1l-2.557-1a.479.479,0,0,0-.614.2l-2.046,3.45a.6.6,0,0,0,.1.65l2.2,1.65c0,.35-.051.65-.051,1s.051.65.051,1l-2.147,1.65a.459.459,0,0,0-.1.65l2.046,3.45a.5.5,0,0,0,.614.2l2.557-1a7.444,7.444,0,0,0,1.739,1l.409,2.65a.5.5,0,0,0,.511.4h4.091a.548.548,0,0,0,.511-.4l.41-2.65a8.658,8.658,0,0,0,1.738-1l2.557,1a.479.479,0,0,0,.614-.2l2.046-3.45a.6.6,0,0,0-.1-.65Zm-7.62,2.5a3.5,3.5,0,1,1,3.58-3.5A3.518,3.518,0,0,1,2017.974,13.5Z"
                    transform="translate(-2008 0)"/>
            </svg>
            <span class="d-none d-lg-inline">Account</span> Settings
        </NavItem>
        <li class="nav-item nav-item-logout">
            <a href="#" @click.prevent="handleLogout" class="nav-link position-relative">
                <svg class="nav-item-icon" xmlns="http://www.w3.org/2000/svg" width="20.5" height="20.5"
                     viewBox="0 0 20.5 20.5">
                    <g transform="translate(-3.25 -3.25)">
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                              d="M9.33,22.5H6.11a1.837,1.837,0,0,1-1.61-2V6.5a1.837,1.837,0,0,1,1.61-2H9.33"/>
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                              d="M24,18.55l4.025-4.025L24,10.5" transform="translate(-5.525 -1.025)"/>
                        <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5px"
                              d="M23.16,18H13.5" transform="translate(-0.66 -4.5)"/>
                    </g>
                </svg>
                Logout
            </a>
        </li>
    </Nav>
</template>

<script>
import Nav from "@/components/nav/Nav";
import NavItem from "@/components/nav/NavItem";
import logout from "@/mixins/logout";

export default {
    name: "NavSecondary",
    components: {Nav, NavItem},
    mixins: [logout],
    methods: {
        async handleLogout(){
            await this.logout();
        }
    }
}
</script>

<style lang="scss">
#app-nav-secondary {
    @include media-breakpoint-up('lg'){
        margin-top: auto;
        margin-bottom: 4rem;
    }
}
</style>