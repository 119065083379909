import axios from 'axios'
import VueCookies from 'vue-cookies'
import * as types from '../mutationTypes'
import {AUTH_USER_URL, LOGOUT_URL, RESET_PASSWORD_URL} from "@/config/api";

export const state = {
    user: null,
    token: VueCookies.get('token')
}

// getters
export const getters = {
    user: state => state.user,
    token: state => state.token,
    check: state => state.user !== null
}


// mutations
export const mutations = {
    [types.SAVE_TOKEN] (state, { token, remember }) {
        state.token = token
        const expires = remember ? new Date(new Date().setFullYear(new Date().getFullYear() + 1)) : 0;
        VueCookies.set('token', token, expires)
    },

    [types.FETCH_USER_SUCCESS] (state, { user }) {
        state.user = user
    },

    [types.FETCH_USER_FAILURE] (state) {
        state.token = null
        VueCookies.remove('token')
    },

    [types.LOGOUT] (state) {
        state.user = null
        state.token = null

        VueCookies.remove('token')
    },

    [types.UPDATE_USER] (state, { user }) {
        state.user = user
    }
}




export const actions = {
    saveToken ({ commit }, payload) {
        commit(types.SAVE_TOKEN, payload)
    },

    async fetchUser ({commit, state}) {
        try {
            const { data } = await axios.get(AUTH_USER_URL, {
                headers: {Authorization: `Bearer ${state.token}`}
            });
            commit(types.FETCH_USER_SUCCESS, { user: data });
        } catch (e) {
            commit(types.FETCH_USER_FAILURE)
        }
    },

    updateUser ({ commit }, payload) {
        commit(types.UPDATE_USER, payload)
    },

    async logout ({ dispatch, commit, state }) {
        try {
            const {data, status} = await axios.get(LOGOUT_URL, {
                headers: {Authorization: `Bearer ${state.token}`}
            });

            dispatch('serverNotice/setNotice', {
                message: data.message,
                status,
            },  {root: true});
            commit(types.LOGOUT);
        }
        catch (e) {
            commit(types.FETCH_USER_FAILURE)
        }
    },

    async checkResetPassword(store, params){
        await axios.get(RESET_PASSWORD_URL, {
            timeout: 10000,
            params,
        });
    }
}