import {guest_route} from "@/config/auth";

const logout = {
    methods: {
        async logout(){
            this.$root.$preloader.start();

            // Log out the user.
            await this.$store.dispatch('auth/logout');

            // clear booking and history
            await this.$store.dispatch('user/clearStoredBookings');
            await this.$store.dispatch('user/clearStoredBalanceHistory');

            // clear new quote
            await this.$store.dispatch('checkout/clearQuote');

            // Redirect to login.
            await this.$router.push({name: guest_route})
        }
    }
}

export default logout;