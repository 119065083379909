// serverErrors
export const SET_ERRORS = 'SET_ERRORS';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_TYPE = 'SET_TYPE';


// checkout
export const HAS_NEW_BOOKING = 'HAS_NEW_BOOKING';
export const SET_NEW_QUOTE = 'SET_NEW_QUOTE';
export const SET_QUOTE = 'SET_QUOTE';
export const FETCH_QUOTE_FAILURE = 'FETCH_QUOTE_FAILURE';
export const SAVE_REFERENCE = 'SAVE_REFERENCE';
export const CLEAR_REFERENCE = 'CLEAR_REFERENCE';

// terms
export const FETCH_PRIVACY_POLICY = 'FETCH_PRIVACY_POLICY';
export const FETCH_TERMS_AND_CONDITIONS = 'FETCH_TERMS_AND_CONDITIONS';
export const FETCH_INSURANCE_RULES = 'FETCH_INSURANCE_RULES';


// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'

// user.js
export const FETCH_USER_BALANCE_HISTORY = 'FETCH_USER_BALANCE_HISTORY'
export const CLEAR_USER_BALANCE_HISTORY = 'CLEAR_USER_BALANCE_HISTORY'

export const FETCH_USER_BOOKINGS = 'FETCH_USER_BOOKINGS'
export const CLEAR_USER_BOOKINGS = 'CLEAR_USER_BOOKINGS'


// units.js
export const FETCH_UNITS_SUCCESS = 'FETCH_UNITS_SUCCESS'
export const FETCH_UNITS_FAILURE = 'FETCH_UNITS_FAILURE'
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS'
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE'
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS'
export const FETCH_VEHICLES_FAILURE = 'FETCH_VEHICLES_FAILURE'
export const FETCH_CONTAINERS_SUCCESS = 'FETCH_CONTAINERS_SUCCESS'
export const FETCH_CUSTOMS_CLEARANCE_SUCCESS = 'FETCH_CUSTOMS_CLEARANCE_SUCCESS'
export const FETCH_CUSTOMS_CLEARANCE_FAILURE = 'FETCH_CUSTOMS_CLEARANCE_FAILURE'

