<template>
    <nav :id="`app-${id}`">
        <ul class="navbar-nav flex-row flex-lg-column justify-content-between">
            <slot></slot>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "Nav",
    props: {
        id: {
            type: String,
            required: true,
        }
    }
}
</script>
