<template>
    <transition name="slide">
        <div v-if="show"
             class="position-fixed alert w-100 text-center m-0 text-white"
             :class="{
                [`bg-${theme}`]: `bg-${theme}`,
                [`alert-${position}`]: `alert-${position}`,
            }">
            {{ message }}
        </div>
    </transition>

</template>

<script>
export default {
    name: "FixedAlert",
    data: () => ({
        hideTimeout: null,
        show: false,
    }),
    watch: {
        message(value) {
            if (this.hideTimeout) clearTimeout(this.hideTimeout);
            this.hideTimeout = setTimeout(() => {
                this.show = false;
            }, 5000);
            this.show = value ? true : false;
        }
    },
    props: {
        message: {
            type: String
        },
        theme: {
            type: String,
            validator: (value) => ['danger', 'success', 'info'].indexOf(value) !== -1,
            default: 'info'
        },
        position: {
            type: String,
            validator: (value) => ['top', 'bottom'].indexOf(value) !== -1,
            default: 'bottom'
        }
    }
}
</script>

<style scoped lang="scss">
.alert {
    z-index: 9999;
    border-radius: 0;

    &-bottom {
        bottom: 0;
    }

    &-top {
        bottom: 0;
    }
}
.slide-enter-active,.slide-leave-active{
    transition: all 0.2s ease;
}
.slide-enter, .slide-leave-to{
    &.alert-bottom{
        transform: translateY(100%);
    }
    &.alert-top{
        transform: translateY(-100%);

    }
}
.slide-enter-to,.slide-leave{
    transform: translateY(0%);
}
</style>